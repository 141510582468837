/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.hidden-input {
    visibility: hidden;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
}

[dir] .card {
    overflow: hidden;
}

ul {
    margin-bottom: 0;
}

.table-black {
    background: #fafafc !important;
}

.table {
    th.active,
    th span:hover {
        svg {
            opacity: 1;
        }
    }

    th svg {
        opacity: .3;
        transition: all .150s ease;
    }
}

.accordion-item.no-shadow {
    box-shadow: none !important;
}

.shadow-1 {
    box-shadow: 0 2px 15px 0 rgb(34 41 47 / 5%) !important;
}

.border-2 {
    border: 2px solid rgba(34, 41, 47, 0.125);
}

.border-primary-2 {
    border: 2px dashed #7367f0;
}

.dragn-drop {
    overflow: hidden;

    .rst__nodeContent {
        width: 100%;
    }

    .rst__rowContents,
    .rst__moveHandle {
        box-shadow: none;
        border: none;
    }

    .rst__moveHandle {
        background-color: #7367f0;
    }

    .rst__row {
        border-radius: 4px;
        box-shadow: 0 2px 15px 0 rgb(34 41 47 / 5%);
    }

    &__image {
        .rst__node {
            height: 100px !important;
        }
    }
}

.dark-layout {
    .table-black {
        background: #242b3d !important;
    }

    .rst__rowContents {
        background-color: #242b3d;
    }
}

.table_sm_padding {
    th, td {
        padding: 10px 5px;
    }

    th:first-child,
    td:first-child {
        padding-left: 15px;
    }

    th:last-child,
    td:last-child {
        padding-right: 15px;
    }
}

// ** DROPZONE

.dropzone {
    font-weight: 500;
    font-size: 24px;
    cursor: cell;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    padding: 30px;
    border: 3px dashed #d8d6de;

    &.invalid {
        border: 3px dashed #ea5455;
    }

    .thumbs__container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }

    .thumb {
        display: inline-flex;
        cursor: move;
        margin-right: 8px;
        margin-bottom: 8px;
        width: auto;
        min-width: 120px;
        height: 155px;
        box-sizing: border-box;
        border-radius: 10px;

        &-delete {
            position: absolute;
            cursor: pointer;
            visibility: hidden;
            top: -11px;
            right: -8px;
            background: #7367f0;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            opacity: 0;
            z-index: 1;
            color: #fff;
            transition: .2s ease;
        }

        &-move {
            font-size: 14px;
            text-align: center;
            position: absolute;
            visibility: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 15px;
            border-radius: 10px;
            opacity: 0;
            color: #fff;
            background: rgba(0, 0, 0, 0.56);
            transition: all .2s ease;
        }
    }

    .thumb__inner {
        position: relative;
        display: flex;
        min-width: 0;
        width: 100%;

        &:hover {
            .thumb-delete {
                visibility: visible;
                opacity: 1;
            }

            .thumb-move {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .thumb__add {
        text-align: center;
        font-size: 18px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 155px;
        height: 155px;
        padding: 15px;
        border-radius: 15px;
        background: rgba(255, 255, 255, 0.10);
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        border: 1px solid #7367f0;
        object-fit: cover;
        object-position: center;
        border-radius: 10px;
    }

    p {
        margin: 0;
    }
}

// ** CART

.cart__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: pre;
    margin-bottom: 0.5rem;

    span {
        text-align: right;
        white-space: pre;
    }

    div {
        white-space: normal;
        text-align: right;
        width: 100%;
    }

    hr {
        width: 100%;
        margin: auto 15px;
        background: transparent;
        border-bottom: 1px dashed #ebe9f191;
    }
}

.react-select-sortable {
    .select__value-container {
        padding: 8px;

        .select__multi-value {
            cursor: move;
            justify-content: space-between;

            svg {
                width: 16px;
                height: 16px;
            }
        }

        .select__multi-value__label {
            font-size: 15px;
        }

        & > div {
            width: 100%;

            &:not(:last-child) {
                margin-bottom: .7rem;
            }
        }
    }
}

// ** TABLE
.table-responsive {
    thead tr {
        white-space: nowrap;
    }
}